import { addHours, addMinutes, isBefore, parseISO, startOfDay } from 'date-fns'
import { parse } from 'papaparse'
import some from 'lodash/some'
import map from 'lodash/map'
import find from 'lodash/find'
import algoliasearch from 'algoliasearch'
import { algoliaClient } from 'src/general-config'
import {
  DISCOUNT_WINDOW,
  PROMO_TYPE_FROM_EXTRA_FIELD,
  PromotionTypeOptions,
  PRICE_KIND_MAP,
  SINGLE_MULTIUSE,
  ALL,
} from 'src/modules/promo/constants'
import { Buffer } from 'buffer/'
import getSymbolFromCurrency from 'currency-symbol-map'
import {
  getProductsDataWithTargetXTypeV2,
  getProductsDataForOtherTargetTypeV2,
} from './productSelectionV2'
import {
  isGlobalExclusive,
  isStackable,
  isTypeExclusive,
  isUniversal,
} from '../constants'

const APP_ID = process.env.ALGOLIA_APP_ID || 'AWP6XJTOHO'
const ALGOLIA_API_KEY =
  process.env.ALGOLIA_API_KEY || '5c9f99984de00baad844fb1c8570aebc'
const client = algoliasearch(APP_ID, ALGOLIA_API_KEY)

const itemsSearchIndex =
  (algoliaClient && algoliaClient.attributesItemsSearchIndex) ||
  'teamfabric-copilotApi-jobs-develop-copilot-item-index'
const getVariantsIndex = client.initIndex(itemsSearchIndex)

const fetchSelectedProductVariants = async (parentId) => {
  try {
    const { hits } = await getVariantsIndex.search('', {
      page: 0,
      facetFilters: [['isParent: false'], [`parent: ${parentId}`]],
    })
    return hits.length === 0 ? [] : hits
  } catch {
    return []
  }
}

const ellipsis = (text, maxLimit) =>
  text && text.length > maxLimit
    ? text.substring(0, maxLimit - 3) + '...'
    : text

const capitalizeFirstLetter = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
}

const checkDataType = (data) => {
  let dataType = Object.prototype.toString.call(data)
  return dataType.substring(8, dataType.length - 1)
}

const validateDecimal = (price) => {
  let fractionalPart = price ? price.toString().split('.')[1] : ''
  if (fractionalPart && fractionalPart.length > 2) {
    return Number.parseFloat(price)
  } else {
    return Number.parseFloat(price).toFixed(2)
  }
}

const displayPriceAndRange = ({ priceRange, currency }) => {
  if (
    priceRange &&
    priceRange.min >= 0 &&
    checkDataType(priceRange.min) === 'Number'
  ) {
    if (priceRange.min === priceRange.max) {
      return `${getSymbolFromCurrency(currency)}${validateDecimal(
        priceRange.min
      )}`
    } else {
      return `${getSymbolFromCurrency(currency)}${validateDecimal(
        priceRange.min
      )} -  ${getSymbolFromCurrency(currency)}${validateDecimal(
        priceRange.max
      )}`
    }
  } else {
    return '-'
  }
}

const multiSelectDataFormatting = (data, stateData, StyledLabel) => {
  return map(data, (obj) => {
    const selectedOptions = stateData.find((option) => {
      return option.label === obj.name
    })
    return {
      label: renderLabel(obj, StyledLabel),
      isSelected: (selectedOptions && selectedOptions.isSelected) || false,
      key: obj.name.replace(/[^A-Z0-9]+/gi, '_'),
      priceListIds: obj.priceListId,
      currency: obj.currency,
    }
  })
}

const renderLabel = (obj, StyledLabel) => {
  return (
    <StyledLabel>
      {`${obj.name.length > 32 ? ellipsis(obj.name, 30) : obj.name}`}
      <div className='currency'>
        {`${obj.currency} ${getSymbolFromCurrency(obj.currency)}`}
      </div>
    </StyledLabel>
  )
}

const getSelectedNameFromAttributeData = ({ selectedAttributes, name }) => {
  let isChecked = false
  Object.keys(selectedAttributes).forEach((attribute) => {
    if (selectedAttributes[attribute].name === name) {
      isChecked = true
    }
  })
  return isChecked
}

const csvReader = ({ files }, getParseData) => {
  var parseData,
    file = files[0]
  const reader = new FileReader()
  reader.onload = (e) => {
    parseData = parse(e.target.result, {
      header: true,
      skipEmptyLines: 'greedy',
      transform: (value) => value.trim(),
    })
    getParseData(parseData)
  }
  reader.readAsText(file)
}

const hexToRgba = (hex, opacity) => {
  const result = hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => '#' + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16))

  return { red: result[0], green: result[1], blue: result[2], opacity }
}

const getBuyGetTargetTypes = ({ buyGetType, promotion }) => {
  if (buyGetType === 'buy') {
    let buyTypes = []
    promotion?.[`${buyGetType}Data`]?.forEach((buy) => {
      buyTypes.push(`set${buy.set}`)
    })
    return buyTypes
  } else if (buyGetType === 'get') {
    let getTypes = []
    promotion?.[`${buyGetType}Data`]?.forEach((get) => {
      if (get.getSkuSet) {
        getTypes.push(get.getSkuSet.set)
      }
    })
    return getTypes
  } else {
    let spendTypes = []
    spendTypes.push('spend')
    return spendTypes
  }
}

const isPartial = ({ attribute, targetTypes, props }) => {
  return some(targetTypes, (targetType) => {
    const selectedAttributes = props[`${targetType}SelectedAttributes`]
    return (
      attribute.value &&
      selectedAttributes &&
      selectedAttributes[attribute.id] &&
      selectedAttributes[attribute.id].values &&
      selectedAttributes[attribute.id].values.length !== attribute.value.length
    )
  })
}

const isFunction = (fn) => typeof fn === 'function'

const noImage =
  'https://d30qpyrmi32nba.cloudfront.net/offers/a42d451af7f0a0e677d0.png'

function getDateValidations(fields, type, globalExclusionUpdate = false) {
  if (fields[type]?.length <= 0) {
    return type === 'startDate' ? 'Enter the start date' : 'Enter an end date'
  }
  if (!globalExclusionUpdate) {
    if (isBefore(fields[type], new Date())) {
      return type === 'startDate'
        ? fields.hasOwnProperty('applicableOn')
          ? 'Start date & Time cannot be in the past'
          : "Start date can't be in past"
        : fields.hasOwnProperty('applicableOn')
        ? 'End date & Time cannot be in the past'
        : "End date can't be in past"
    }
  }
}

const encodeId = (id) => {
  return Buffer.from(id, 'utf-8').toString('base64')
}

const decodeId = (id) => {
  return Buffer.from(id, 'base64').toString('utf-8')
}

const formattSkuData = ({ hit }) => {
  const hasVariants = hit._source?.childrenItems?.length;
  return {
    _id: hit._source.productId,
    itemId: hit._source.itemId,
    title: hit._source.title,
    sku: hit._source.productSku,
    isChildLoading: !!hasVariants,
    parentSku: hit._source.parentSku,
    images: [{ source: [{url: hit._source.image}] }],
    items: hasVariants ? [{ title: hit._source?.childrenItems?.[0], sku: hit._source?.childrenItems?.[0] }] : []
  }
}

export const transformProductSearchData = (productData) => {
  const products = productData?.map((hit) => formattSkuData({ hit }))
  return products;
}

const getSelectedSKUsHeader = ({
  selectedCriteria,
  attributeItemsDetails,
  selectedCategoriesSkusCount,
  tempSelectedSkusCount,
}) => {
  if (selectedCriteria === 'Attributes') {
    return attributeItemsDetails?.items?.length || 0
  } else {
    if (selectedCriteria === 'Categories') {
      return selectedCategoriesSkusCount
    } else {
      return tempSelectedSkusCount || 0
    }
  }
}

const checkIsBuyGetSelected = (state) => {
  if (state.selectedPromoTypeRadio === 'Buy-Get') {
    return (
      state.promotion?.buyOperator ||
      (!state.promotion?.buyOperator && state.promotion?.getType !== 'CART')
    )
  }
  return false
}

export const getBulkCouponCodeCSVHeaders = () => {
  return ['Promotion ID', 'Coupon code', 'User ID', 'Status']
}

const checkIfSetSelected = (props, data) => {
  const { promotion } = props
  const { getData } = promotion || {}
  let isSetSelected = false
  if (
    checkTruthy(getData?.length, props.targetType?.includes(DISCOUNT_WINDOW))
  ) {
    getData?.forEach((singleDiscount, i) => {
      if (
        checkTruthy(
          singleDiscount?.getSelectedSkuSets?.length,
          props.targetType !== `discount${i}`
        )
      ) {
        singleDiscount?.getSelectedSkuSets?.forEach((set) => {
          if (data.includes(set.value)) {
            isSetSelected = true
          }
        })
      }
    })
  }
  return isSetSelected
}

const checkTruthy = (condition1, condition2) => {
  return condition1 && condition2
}

const checkDisabledForGetSide = ({ props, isBuy }) => {
  const { buyData, spendData, buyOperator } = props?.promotion || {}
  const rules =
    props[`set${buyData[buyData?.length - 1]?.set}IncludeSelectedRules`]
  const setProducts =
    props[`set${buyData[buyData?.length - 1]?.set}IncludeProducts`]
  const spendRules = props[`spendIncludeSelectedRules`]
  const spendProducts = props[`spendIncludeProducts`]
  const list = isBuy ? setProducts : spendProducts
  const hasProducts =
    (buyOperator
      ? !buyData[0].minimumQuantity
      : !spendData?.minimumCartValue) ||
    (buyData.length === 1 &&
      list &&
      list?.length === 0 &&
      !isAllSkusSelected(isBuy ? rules : spendRules)) ||
    false
  if (props?.disabled) {
    return true
  } else if (!buyOperator) {
    if (spendData?.skuCondition === '*') {
      return !spendData?.minimumCartValue
    } else {
      return hasProducts
    }
  } else {
    return hasProducts
  }
}

export const getMinTime = (date) => {
  return date === '' || isToday(date)
    ? new Date()
    : startOfDay(parseISO(new Date()))
}

export const getMaxTime = () => {
  const parsedDate = parseISO(new Date())
  const dayStart = startOfDay(parsedDate)
  const hourlyDate = addHours(dayStart, 23)
  return addMinutes(hourlyDate, 45)
}

export const isToday = (date) => {
  const today = new Date()
  date = new Date(date)
  return (
    date.getDate() == today.getDate() &&
    date.getMonth() == today.getMonth() &&
    date.getFullYear() == today.getFullYear()
  )
}

export function combineDateAndTime(date, time) {
  const combinedDate = new Date(date)
  combinedDate.setHours(time.getHours())
  combinedDate.setMinutes(time.getMinutes())
  combinedDate.setSeconds(time.getSeconds())
  return combinedDate
}

const getPriceKind = (priceKind, key, discountId = '') => {
  let priceKindData = {
    [key]: 'PRICE_KIND',
    value: priceKind,
    operator: 'EQUAL',
  }
  if (discountId !== '') {
    priceKindData.discountId = discountId
  }
  return priceKindData
}

export const getPrevDiscountKind = (key, discountId) => {
  let prevDiscount = {
    [key]: 'PREVIOUS_DISCOUNTS',
    value: 0,
    operator: discountId === 1 ? 'EQUAL' : 'GREATER_THAN',
    discountId: `${discountId}`,
  }
  return prevDiscount
}

const getAllExtraFieldsFromData = (promotion) => {
  let {
    extra,
    promo,
    promoCodes,
    eligiblePriceList,
    type,
    buyOperator,
    singleMultiUse,
  } = promotion
  let buyData = getBuyData(promotion)
  let spendData = getSpendData(promotion)
  extra.buyData = buyData.length === 0 ? '' : buyData
  extra.buyOperator =
    type === 'SPEND_GET' || type === 'CART' || type === 'SHIPPING'
      ? ''
      : buyOperator
  extra.buyXSameSku = ''
  extra.getData = getGetData(promotion)
  extra.getType = getGetType(promotion)
  extra.getYSameSku = null
  extra.selectedExplicitOption = singleMultiUse
    ? SINGLE_MULTIUSE[singleMultiUse]
    : promoCodes && promoCodes.length > 1
    ? 'isSingle'
    : 'isMultiple'
  extra.selectedPriceListOptions = eligiblePriceList?.length
    ? 'isSpecific'
    : 'isAll'
  extra.selectedEligiblePriceList = eligiblePriceList
  extra.selectedPriceRadio = promo?.length
    ? getSelectedPriceKind(promo[0])
    : 'sale-base-price'
  extra.selectedPromoTypeRadio =
    type !== 'SHIPPING'
      ? PROMO_TYPE_FROM_EXTRA_FIELD[type]
      : getPromoTypeFromPromo(promo)
  extra.spendData = Object.entries(spendData).length === 0 ? '' : spendData
  extra.userKind = 'All'
  extra.targetType = 'targetX'
  extra.promoTiers = getPromoTiersFromPromo(promotion)
  extra.target = getTargetTypesFromPromoData(promotion)
  return extra
}

const getBuyData = (promotion) => {
  let buyData = []
  const { type, promo } = promotion
  if (type === 'BUYGET') {
    buyData = []
    promo?.length &&
      promo[0]?.condition?.forEach((singleCondition) => {
        let singleBuyData = {
          set: singleCondition.set,
          minimumQuantity:
            singleCondition?.conditions?.filter((condition) => {
              if (
                condition?.key === 'QUANTITY' &&
                condition?.operator === 'GREATER_THAN_OR_EQUAL_TO'
              ) {
                return condition
              }
            })[0]?.value || 0,
        }
        buyData.push(singleBuyData)
      })
  }
  if (type === 'SPEND_GET' || type === 'CART' || type === 'SHIPPING') {
    return [
      {
        minimumQuantity: '',
        set: 'A',
      },
    ]
  }
  return buyData
}

const checkPromoType = (type) => {
  return (
    type === 'BUYGET' ||
    type === 'SPEND_GET' ||
    type === 'CART' ||
    type === 'SHIPPING'
  )
}

const getGetDiscountType = (PromotionTypeOptions, singleDiscount) => {
  let getDiscountType = {}
  PromotionTypeOptions?.forEach((option, index) => {
    if (singleDiscount?.autoAdd) {
      getDiscountType = {
        id: index,
        name: 'Free',
        label: 'Free',
      }
    } else if (option?.key === singleDiscount?.unit) {
      getDiscountType = {
        id: index,
        name: option.title,
        label: option.title,
      }
    }
  })
  return getDiscountType
}

const getGetData = (promotion) => {
  let getData = {}
  const { type, promo } = promotion
  if (checkPromoType(type)) {
    getData = []
    promo?.length &&
      promo[0]?.discount?.forEach((singleDiscount, i) => {
        let getSkuSet = getGetSkuSet(singleDiscount, i)
        let singleGetData = {
          getMaxQuantity: singleDiscount?.ON?.quantity,
          getDiscountType: getGetDiscountType(
            PromotionTypeOptions,
            singleDiscount
          ),
          getDiscountAmount: singleDiscount?.value,
          getDiscountSortBy: singleDiscount?.ON?.sortBy
            ? singleDiscount?.ON?.sortBy
            : ALL,
          getShippingType: singleDiscount?.ON?.kind?.includes('SERVICE')
            ? singleDiscount?.ON?.value
            : [],
          getShippingApplicableOn: singleDiscount?.ON?.kind?.includes('SERVICE')
            ? getSelectedShippingConfig(promo[0]?.condition)
            : '',
          getSelectedSkuSets:
            promo[0]?.discount[0]?.ON?.kind !== 'SKU'
              ? []
              : getGetSelectedSkuSets(singleDiscount, promo),
          getSkuSet: Object.entries(getSkuSet).length === 0 ? '' : getSkuSet,
          isAllSelected:
            promo[0]?.condition?.length === singleDiscount?.set?.length,
          isClickedAll:
            promo[0]?.condition?.length === singleDiscount?.set?.length,
        }
        getData.push(singleGetData)
      })
  }
  return getData
}

const getGetSkuSet = (discount, i) => {
  if (!discount?.set?.length && discount?.ON?.kind === 'SKU') {
    return {
      set: `discount${i}`,
    }
  }
  return {}
}

const getGetSelectedSkuSets = (discount, promo) => {
  let getSelectedSkuSets = [
    {
      label: 'New set of SKUs (below)',
    },
  ]
  if (discount?.set?.length) {
    getSelectedSkuSets = discount?.set?.map((singleSet) => {
      return {
        id: `set${singleSet}`,
        label: `Set ${singleSet}`,
        value: `set${singleSet}`,
        optionChecked: true,
      }
    })
    if (promo[0]?.condition?.length === getSelectedSkuSets?.length) {
      let firstIndex = 'All (SKU set '
      getSelectedSkuSets?.forEach((data) => {
        firstIndex += `${data?.id?.replace('set', '')}&`
      })
      firstIndex = firstIndex.slice(0, firstIndex.length - 1)
      firstIndex += ')'
      getSelectedSkuSets.push({ id: 'All', label: firstIndex, value: 'All' })
    }
  }
  return getSelectedSkuSets
}

const getGetType = (promotion) => {
  const { type, promo } = promotion
  if (
    type === 'BUYGET' ||
    type === 'SPEND_GET' ||
    type === 'CART' ||
    type === 'SHIPPING'
  ) {
    if (promo[0]?.discount[0]?.ON?.kind === 'SERVICE_CART') {
      return 'SHIPPING'
    } else {
      return promo[0]?.discount[0]?.ON?.kind
    }
  }
  return ''
}

const getUIPriceKind = (priceKind) => {
  for (let key of Object.keys(PRICE_KIND_MAP)) {
    if (PRICE_KIND_MAP[key] === priceKind) {
      return key
    }
  }

  return 'sale-base-price'
}
const getSelectedPriceKind = ({ targetProducts = [], condition }) => {
  let priceKind = targetProducts.find((obj) => obj.kind === 'PRICE_KIND')?.value

  if (priceKind) {
    return getUIPriceKind(priceKind)
  }

  priceKind = condition[0]?.conditions.find(
    (condition) => condition.key === 'PRICE_KIND'
  )?.value
  return getUIPriceKind(priceKind)
}

const getSelectedShippingConfig = (condition) => {
  const shippingConfig =
    condition[0]?.conditions.find(
      (condition) => condition.key === 'SHIPPING_DISCOUNT_QUALIFICATION'
    )?.value || ''
  return shippingConfig
}

const getSpendData = (promotion) => {
  const { type, promo } = promotion
  const conditions = promo[0]?.condition[0]?.conditions
  let skuCondition = '*'
  conditions?.forEach((condition) => {
    if (
      condition?.value !== '*' &&
      (condition?.key === 'SKU' ||
        condition?.key === 'CATEGORY' ||
        condition?.key === 'COLLECTION' ||
        condition?.key === 'ATTRIBUTE')
    ) {
      if (condition?.operator === 'IN') {
        skuCondition = 'INCLUDE'
      } else if (condition?.operator === 'NOT_IN') {
        skuCondition = 'EXCLUDE'
      }
    }
  })
  if (type === 'SPEND_GET' || type === 'CART' || type === 'SHIPPING') {
    return {
      minimumCartValue: conditions?.filter(
        (condition) =>
          condition?.key === 'ORDER_VALUE' &&
          condition?.operator === 'GREATER_THAN_OR_EQUAL_TO'
      )[0]?.value,
      skuCondition: skuCondition,
      spendSkuSet: '',
    }
  }
  return {}
}

const getPromoTypeFromPromo = (promo) => {
  let promoType = PROMO_TYPE_FROM_EXTRA_FIELD.PRODUCT
  promo?.length &&
    promo[0]?.discount?.forEach((singleDiscount) => {
      if (singleDiscount?.ON?.kind === 'SERVICE_CART') {
        promoType = PROMO_TYPE_FROM_EXTRA_FIELD.QUANTITY
      }
    })
  promo?.length &&
    promo[0]?.condition[0]?.conditions?.forEach((singleCondition) => {
      if (singleCondition?.key === 'ORDER_VALUE') {
        promoType = PROMO_TYPE_FROM_EXTRA_FIELD.SPEND_GET
      }
    })
  return promoType
}

const getPromoTiersFromPromo = (promotion) => {
  const { promo, type } = promotion
  const isBuyGetType = type === 'BUYGET'
  let promoTiers = []
  promo?.forEach((singlePromo, index) => {
    const sortBy = singlePromo?.discount[0]?.ON?.sortBy
    let isShipping = promo[index]?.discount[0]?.ON?.kind.includes('SERVICE')
    let promoTier = {
      id: index,
      typeWithValues: {
        promotionTypeValue: !isShipping ? singlePromo?.discount[0]?.value : '',
        selectedSortBy: sortBy ? sortBy : ALL,
        promotionTypeValueIfDiscountExist:
          !isShipping && !isBuyGetType && singlePromo?.discount.length > 1
            ? singlePromo?.discount[1]?.value
            : '',
        dynamicDiscount: !isBuyGetType && singlePromo?.discount.length > 1,
        ...getShippingPromoTypeData(promotion, index, isShipping, isBuyGetType),
      },
    }
    singlePromo?.condition[0]?.conditions?.forEach((singleCondition) => {
      if (
        singleCondition?.key === 'QUANTITY' &&
        singleCondition?.operator === 'GREATER_THAN_OR_EQUAL_TO'
      ) {
        promoTier.buyValue = singleCondition?.value
      }
    })
    !isShipping &&
      PromotionTypeOptions?.forEach((option) => {
        if (option?.key === singlePromo?.discount[0]?.unit) {
          promoTier.typeWithValues.selectedPromotionType = option.title
        }
      })
    promoTiers.push(promoTier)
  })
  return promoTiers
}

const getShippingPromoTypeData = (
  promotion,
  index,
  isShipping,
  isBuyGetType
) => {
  const { promo } = promotion
  let promoTypeOption = PromotionTypeOptions?.find(
    (option) => option?.key === promo[index]?.discount[0]?.unit
  )

  if (
    promoTypeOption?.key === '%OFF' &&
    promo[index]?.discount[0]?.unit === '%OFF' &&
    promo[index]?.discount[0]?.value === 100
  ) {
    promoTypeOption = { ...promoTypeOption, title: 'Free' }
  }
  return {
    selectedPromotionType: 'Shipping off',
    selectedShippingPromotionType: isShipping
      ? {
          id: 0,
          name: promoTypeOption?.title,
        }
      : 'Percentage off',
    selectedShippingType: isShipping
      ? promo[index]?.discount[0]?.ON?.value
      : [],
    shippingApplicableOn: isShipping
      ? getSelectedShippingConfig(promo?.[0]?.condition)
      : '',
    shippingPromotionTypeValue: isShipping
      ? promo[index]?.discount[0]?.value
      : '',
    shippingPromotionTypeValueIfDiscountExist:
      isShipping && !isBuyGetType && promo[index]?.discount.length > 1
        ? promo[index]?.discount[1]?.value
        : '',
  }
}

const getTargetTypesFromPromoData = (promotion) => {
  return {
    types: getProductsDataWithTargetType(promotion),
  }
}

const getProductsDataWithTargetType = (promotion) => {
  let { promo, type } = promotion
  if (
    PROMO_TYPE_FROM_EXTRA_FIELD[type] === 'Buy-Get' ||
    getPromoTypeFromPromo(promo) === 'Buy-Get'
  ) {
    let allTargetTypes = []
    promo[0]?.condition?.forEach((singleCondition) => {
      let checkAllInSpend = false
      if (type === 'SPEND_GET' || type === 'CART' || type === 'SHIPPING') {
        if (!checkAllInSpend) {
          allTargetTypes.push('spend')
        }
      } else {
        allTargetTypes.push(`set${singleCondition?.set}`)
      }
    })
    promo[0]?.discount?.forEach((singleDiscount, index) => {
      if (
        singleDiscount?.ON?.kind === 'SKU' &&
        singleDiscount?.set?.length === 0
      ) {
        allTargetTypes.push(`discount${index}`)
      }
    })
    let allTargetTypesData = {}
    allTargetTypes?.forEach((targetType) => {
      allTargetTypesData[targetType] = getProductsDataForOtherTargetTypeV2({
        promotion,
        targetType,
      })
    })
    return allTargetTypesData
  } else {
    return getProductsDataWithTargetXTypeV2({ promotion })
  }
}

const getSelectedValuesFromCondition = (condition, key) => {
  let selectedValues = []
  condition?.conditions?.forEach((singleCondition) => {
    if (singleCondition?.key === key) {
      selectedValues = singleCondition?.value
    }
  })
  return selectedValues
}

const getValuesForBuyAndSpend = ({ targetType, promo, key }) => {
  let selectedValues = []
  if (targetType.includes('set') || targetType === 'spend') {
    promo[0]?.condition?.forEach((condition) => {
      if (targetType.includes(condition?.set) || targetType === 'spend') {
        selectedValues = getSelectedValuesFromCondition(condition, key)
      }
    })
  }
  return selectedValues
}

const getValuesForSet = (promotion, targetType, key) => {
  const { promo } = promotion
  let selectedValues = []
  selectedValues = getValuesForBuyAndSpend({ targetType, promo, key })
  if (targetType.includes('discount')) {
    promo[0]?.targetProducts?.forEach((targetProduct) => {
      if (
        targetType.includes(targetProduct?.discountId) &&
        targetProduct?.kind === key
      ) {
        selectedValues = targetProduct?.value
      }
    })
  }
  return selectedValues
}

export const getCategoriesSkuCount = (promotion, targetType) => {
  const { extra } = promotion
  const { products } = extra
  const { categories } = products
  let categoriesSkusCount = 0
  let selectedCategories = getValuesForSet(promotion, targetType, 'CATEGORY')
  categories?.forEach((category) => {
    if (selectedCategories.includes(category?.id)) {
      categoriesSkusCount += category?.categorySkuCount
    }
  })
  return categoriesSkusCount
}

export const createAttributeTree = ({ attributes, targetType = 'targetX' }) => {
  let tree = {}
  if (targetType === 'spend' || targetType === 'targetX') {
    attributes?.forEach((attribute) => {
      tree[attribute?.parent] = {
        name: attribute?.parent,
        values: tree[attribute?.parent]?.values
          ? tree[attribute?.parent]?.values.concat([attribute?.value])
          : [attribute?.value],
      }
    })
  }
  return tree
}

export const isAllSkusSelected = (rules = []) =>
  rules?.length && rules[0]?.value === 'All'

export const isIncludeProductAdded = ({ rules, products }) => {
  return isAllSkusSelected(rules) || products?.length
}

export const isGetDiscountSkuTypeOnly = ({
  includeRules = [],
  excludeRules = [],
}) => {
  const targetConditions = ['Categories', 'Collections', 'Attributes']
  const isCatgeoryAttributesSelected = [...includeRules, ...excludeRules].find(
    (rule) => targetConditions.includes(rule.value)
  )
  return !isCatgeoryAttributesSelected
}

export const getTargetErrors = ({ rules = [], products = [] }) => {
  const productErrors = {}
  rules.forEach((rule) => {
    if (rule.id !== 'all') {
      productErrors[`error${rule.value}`] = find(
        products,
        (product) => product.key === rule.value
      )
        ? ''
        : `Select ${rule.value}`
    }
  })
  return productErrors
}

const isIdV2Account = localStorage.getItem('isLoggedInWithIdV2') === 'true'

let isArrayEmpty = (items) => {
  return items === null || items === undefined || items.length === 0
}

let productSelectionSortOrderMap = {
  skus: 0,
  category: 1,
  collection: 2,
  attribute: 3,
}

function addSortOrderForDisplay(productTargets) {
  return productTargets.map((productTarget) => ({
    ...productTarget,
    sortOrder: productSelectionSortOrderMap[productTarget.id],
  }))
}

const getSelectedStackingType = ({ level, stackable, isAlwaysApplied }) => {
  if (isAlwaysApplied) {
    return isUniversal
  } else if (stackable) {
    return isStackable
  } else if (!level) {
    return isGlobalExclusive
  } else if (level && !stackable) {
    return isTypeExclusive
  }
}

export {
  fetchSelectedProductVariants,
  capitalizeFirstLetter,
  checkDataType,
  displayPriceAndRange,
  csvReader,
  multiSelectDataFormatting,
  ellipsis,
  hexToRgba,
  isFunction,
  noImage,
  getDateValidations,
  getBuyGetTargetTypes,
  isPartial,
  getSelectedNameFromAttributeData,
  encodeId,
  decodeId,
  getSelectedSKUsHeader,
  checkIsBuyGetSelected,
  checkDisabledForGetSide,
  renderLabel,
  checkIfSetSelected,
  getPriceKind,
  getAllExtraFieldsFromData,
  isIdV2Account,
  isArrayEmpty,
  addSortOrderForDisplay,
  getSelectedStackingType,
}
