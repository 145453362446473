import React, { Component } from 'react'
import { isBefore, isAfter, parseISO } from 'date-fns'
import { navigate } from '@reach/router'
import PageHeading from 'components/page-heading'
import {
  getProductById,
  getPriceListCurrency,
  getCurrency,
} from 'src/api/product'
import CreatePrice from './create-price'
import { Table, Snackbar, Empty, theme } from '@teamfabric/copilot-ui'
import SkeletonLoader from 'components/skeleton-loader'
import { noImage } from '../../../lib/utils/helper'
import { HEADERS_FOR_PRODUCTS } from './headers'
import {
  StyledWrapper,
  StyledContent,
  StyledVariants,
  StyledProductHistory,
} from './styles'
import { displayPriceAndRange, encodeId, decodeId } from 'lib/utils/helper'
import { OFFER_PRICE_PRODUCT_LIST_ROUTE } from 'lib/constants/page-links'
import {
  EMPTY_PRODUCT_DETAIL_HEADING,
  EMPTY_PRODUCT_DETAIL_TEXT,
  GET_FAILURE,
} from './constants'
import { isFeatureActive } from 'src/api/featureFlag'
import { FEATURE_FLAGS } from 'src/general-config'
import { hasPermission } from 'src/api/rolePermissions'
import { CREATE_PERMISSIONS } from 'src/lib/constants/constants'
const PaginationOnProductPrice = isFeatureActive({
  flagName: FEATURE_FLAGS?.PAGINATION_ON_PRODUCT_PRICE,
})

const RenderContent = ({
  productId,
  product,
  currency,
  priceListId,
  checkNoVariants,
  productIsExpired,
  fetchProduct,
  renderToaster,
  isLoading,
  setIsLoading,
  getCreatePriceItemId,
  getCreatePriceItemSku,
  renderPriceHistory,
  getCreatePriceTitle,
}) => {
  if (productId) {
    productId = decodeId(productId)
  }
  const noVariants = checkNoVariants(product)
  let itemIdArray = []
  let itemSkuArray = []
  product &&
    product.items &&
    product.items.map((el) => {
      itemIdArray.push(el.itemId)
      itemSkuArray.push(el.sku)
    })
  const productExpired = productIsExpired(product.endDate)
  let anyActivePrice = false
  product &&
    product.items &&
    product.items.map((variant) => {
      if (variant?.livePrices?.length > 0) {
        anyActivePrice = true
      }
    })

  return (
    <StyledContent data-testid='content'>
      <PageHeading
        handleClick={() => navigate(OFFER_PRICE_PRODUCT_LIST_ROUTE)}
        data-testid='header-back-button'
      >
        <p className='page-title uppercase'>ALL PRICES</p>
      </PageHeading>
      <div className='product_content'>
        <div className='product-details-container' data-testid='container'>
          {product &&
          product.images &&
          product.images.length > 0 &&
          product.images[0].source &&
          product.images[0].source.length > 0 ? (
            <div className='product-image'>
              <img
                src={product.images[0].source[0].url || noImage}
                alt='product'
                onError={(e) => (e.target.src = noImage)}
              />
            </div>
          ) : (
            <div className='product-image'>
              <img src={noImage} alt='product' />
            </div>
          )}
          <div>
            <div className='product-details'>
              <div>
                <p className='title'> {product.title} </p>
                {/* current Price will reflect here */}
              </div>
              <div className='price-range'>
                <p className='price-text'>
                  Price
                  <span className='price-value'>
                    {' '}
                    {displayPriceAndRange({
                      priceRange: product.priceRange,
                      currency,
                    })}
                  </span>
                </p>
                {!noVariants && (
                  <p className='price-text'>
                    Variant
                    <span className='price-value'>
                      {' '}
                      {product && product.items && product.items.length}
                    </span>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        {hasPermission(CREATE_PERMISSIONS.CREATE_PRICES) && (
          <>
            {!noVariants && (
              <div className='create_price_title'>
                Add price to all variants
              </div>
            )}
            {noVariants ? (
              <div>
                <div
                  className='create-price'
                  data-testid='product-create-price'
                >
                  <div className='create_price_title'>Add SKU pricing</div>
                  <CreatePrice
                    priceListId={priceListId}
                    isVariant={true}
                    itemSku={[product?.sku]}
                    itemId={[product.itemId]}
                    fetchProduct={() =>
                      fetchProduct({
                        productId: productId,
                        priceListId,
                      })
                    }
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    renderToaster={renderToaster}
                    isExpired={productExpired}
                    currency={currency}
                    promoDates={product.promoDates}
                    // isPriceUpcomingOrActive={anyUpcomingPrice}
                  />
                </div>
              </div>
            ) : (
              <CreatePrice
                priceListId={priceListId}
                title={getCreatePriceTitle(noVariants)}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                renderToaster={renderToaster}
                itemSku={getCreatePriceItemSku({
                  noVariants,
                  product,
                  itemSkuArray,
                })}
                itemId={getCreatePriceItemId({
                  noVariants,
                  product,
                  itemIdArray,
                })}
                fetchProduct={() => fetchProduct({ productId, priceListId })}
                isExpired={productExpired}
                currency={currency}
                promoDates={product.promoDates}
                isPriceUpcomingOrActive={anyActivePrice}
              />
            )}
          </>
        )}
        {renderPriceHistory(product, noVariants)}
      </div>
    </StyledContent>
  )
}

export default class ProductDetails extends Component {
  state = {
    isLoading: false,
    product: null,
    error: '',
    selectedProduct: { product: null },
    activePage: 1,
    perPage: 10,
  }

  componentDidMount = async () => {
    let { productId } = this.props
    if (productId) {
      productId = decodeId(productId)
    }
    const { priceListId } = this.props
    this.setState({ priceListId: priceListId })
    if (productId) {
      this.fetchProduct({ productId, priceListId })
    }
    try {
      const currencyLocalisation = isFeatureActive({
        flagName: FEATURE_FLAGS?.CURRENCY_LOCALISATION,
      })
      if (currencyLocalisation) {
        const { data } = await getPriceListCurrency(priceListId)
        this.setState({ currency: data.currency })
      } else {
        const { data } = await getCurrency()
        this.setState({ currency: data.currencyCode })
      }
    } catch (error) {
      const errorMessage =
        error.response && error.response.data ? error.response.data.message : ''
      return this.renderToaster('alert', errorMessage)
    }
  }
  setIsLoading = (isLoading) => {
    this.setState({ isLoading: isLoading })
  }

  handlePaginationProduct = (pageIndex) => {
    let { priceListId, productId } = this.props
    if (productId) {
      productId = decodeId(productId)
    }
    const { perPage } = this.state
    const offset = (pageIndex - 1) * perPage
    this.setState({ activePage: pageIndex })
    this.fetchProduct({ productId, priceListId, offset })
  }

  fetchProduct = async ({ productId, priceListId, offset }) => {
    try {
      this.setState({ isLoading: true })
      const { data } = await getProductById({
        id: productId,
        priceListId: priceListId,
        limit: this.state.perPage,
        offset: offset || 0,
      })
      this.setState({ product: data, isLoading: false })
    } catch (e) {
      if (e.response && e.response.data) {
        this.setState({ error: e.response.data.message, isLoading: false })
        return
      }
      this.setState({ error: GET_FAILURE, isLoading: false })
      return
    }
  }

  productIsExpired = (endDate) => {
    return isBefore(parseISO(endDate), new Date())
  }

  checkIsNotCurrent = (date) => {
    return isAfter(parseISO(date), new Date())
  }

  closeSnackBar = (error) => {
    if (error === GET_FAILURE) {
      navigate(OFFER_PRICE_PRODUCT_LIST_ROUTE)
    }
    this.onClose()
  }

  renderSnackBar = (error, message, type) => {
    setTimeout(() => {
      this.closeSnackBar(error)
    }, 6000)
    return (
      <div className='snack-bar' data-testid='snack-bar'>
        <Snackbar
          show
          onDismiss={() => {
            this.closeSnackBar(error)
          }}
          label={type === 'message' ? message : error}
          dismissable
          kind={type}
          textColor={theme.palette.brand.primary.white}
          width='600px'
        />
      </div>
    )
  }

  render() {
    const { isLoading, product, showToaster, type, message, error, currency } =
      this.state
    const { productId, priceListId } = this.props
    return (
      <StyledWrapper>
        {/* <SideNav location={this.props.location} /> */}
        {isLoading && <SkeletonLoader screen='details' type='price' />}
        {product && !isLoading && (
          <RenderContent
            productId={productId}
            product={product}
            currency={currency}
            getCreatePriceTitle={this.getCreatePriceTitle}
            priceListId={priceListId}
            checkNoVariants={this.checkNoVariants}
            productIsExpired={this.productIsExpired}
            fetchProduct={this.fetchProduct}
            renderToaster={this.renderToaster}
            isLoading={isLoading}
            setIsLoading={this.setIsLoading}
            getCreatePriceItemId={this.getCreatePriceItemId}
            getCreatePriceItemSku={this.getCreatePriceItemSku}
            renderPriceHistory={this.renderPriceHistory}
          />
        )}
        {showToaster && this.renderSnackBar(error, message, type)}
      </StyledWrapper>
    )
  }

  renderToaster = (type, message) => {
    this.setState({
      showToaster: true,
      message: message,
      type: type,
    })
  }

  onClose = () => {
    this.setState({ showToaster: false })
  }

  checkNoVariants = (product) => {
    return product?.items?.length === 0
  }

  getCreatePriceTitle = (noVariants) => {
    if (noVariants) {
      return 'Add price to this variant'
    } else {
      return 'Add price to all variants'
    }
  }

  getCreatePriceItemId = ({ noVariants, product, itemIdArray }) => {
    if (noVariants) {
      return [product.itemId]
    } else {
      return itemIdArray
    }
  }

  getCreatePriceItemSku = ({ noVariants, product, itemSkuArray }) => {
    if (noVariants) {
      return [product.sku]
    } else {
      return itemSkuArray
    }
  }

  renderPriceHistory = (product, noVariants) => {
    return (
      <StyledVariants isProduct>
        {this.renderProductHistory(product, noVariants)}
      </StyledVariants>
    )
  }

  getTableTitle = (noVariants) => {
    return HEADERS_FOR_PRODUCTS(!noVariants)
  }

  getDataForNoVariant = (offers, noVariants) => {
    return offers?.items?.map((item) => {
      const obj = { ...item }
      if (obj.price && obj.price.offers) {
        let tempOffers = []
        obj.price.offers.forEach((offer) => {
          if (!this.productIsExpired(offer.endDate)) {
            tempOffers.push(offer)
          }
        })
        obj.price.offers = tempOffers
        const price = {
          ...obj.price,
          ...obj.price?.offers[0]?.price,
          startDate: obj.price?.offers[0]?.startDate,
          endDate: obj.price?.offers[0]?.endDate,
        }
        price.offers = this.checkIsNotCurrent(price?.offers[0]?.startDate)
          ? price?.offers.slice(1, 3)
          : price?.offers.slice(1, 4)
        price.offers.map((offer) => {
          return offer.hasLink || false
        })
        price.offers.push({
          itemId: price.itemId,
          hasLink: true,
          'data-testid': `history-${price.itemId}`,
          link: `/offers/price/variant/${encodeId(price?.itemId.toString())}/${
            price.priceListId
          }`,
        })
        obj.price = price
        obj.children = obj.price.offers
        obj.noVariants = noVariants
      }
      return obj
    })
  }

  renderProductHistory = (offers, noVariants) => {
    const emptyTableText = hasPermission(CREATE_PERMISSIONS.CREATE_PRICES)
      ? EMPTY_PRODUCT_DETAIL_TEXT
      : ''
    const { perPage, activePage } = this.state
    let data = []
    const totalRecords = offers?.price?.query?.count
    if (!noVariants) {
      data = this.getDataForNoVariant(offers, noVariants)
    } else {
      if (offers && offers.price) {
        data = offers.price.offers.map((item) => {
          const obj = { ...item }
          obj.noVariants = noVariants
          return obj
        })
      }
    }
    return (
      <StyledProductHistory>
        {data && data.length ? (
          <Table
            cellClassName='table-cell'
            columns={this.getTableTitle(noVariants)}
            data={data}
            showPagination={PaginationOnProductPrice}
            totalRecords={totalRecords}
            activePageNumber={activePage}
            handlePagination={this.handlePaginationProduct}
            perPage={perPage}
            enableSort
            onRowClick={(event, rowDetails) => {
              if (
                typeof event.target.className === 'string' &&
                !event.target.className.includes('expansion-cell') &&
                rowDetails.itemId
              ) {
                navigate(
                  `/offers/price/variant/${encodeId(
                    rowDetails?.itemId.toString()
                  )}/${this.props.priceListId}`
                )
              }
            }}
          />
        ) : (
          <div className='no-price' data-testid='empty-list'>
            <Empty
              className='empty'
              markdown={true}
              primaryText={EMPTY_PRODUCT_DETAIL_HEADING}
              secondaryText={emptyTableText}
            />
          </div>
        )}
      </StyledProductHistory>
    )
  }
}
