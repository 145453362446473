import API, { API_URL } from '../index'
import { isFeatureActive } from 'src/api/featureFlag'
import { FEATURE_FLAGS } from 'src/general-config'

export const getGroupTree = (type) => {
  return API.offersConnector.get(API_URL.GROUP_TREE(type))
}

export const getItems = (data, type) => {
  return API.offersConnector.post(
    API_URL.ITEM_PRODUCT_GROUP({ request: data, type }),
    { group: data?.group }
  )
}

export const getImportList = ({ limit, offset }) => {
  return API.offers.get(API_URL.ITEM_IMPORT_LIST(limit, offset))
}

export const itemsUpload = (data) => {
  return API.offers.post(API_URL.GET_UPLOAD_URL(), data)
}

export const itemsSearch = (data) => {
  return API.offers.post(API_URL.ITEMS_SEARCH(), data)
}

export const getCurrency = () => {
  return API.offers.get(API_URL.GET_CURRENCY())
}

export const getPriceListCurrency = (priceListId) => {
  return API.offers.get(API_URL.GET_PRICE_LIST_CURRENCY(priceListId))
}

export const getPriceKinds = (data) => {
  return API.offers.post(API_URL.GET_PRICE_KIND(), data)
}

export const createPrice = (price) => {
  return API.offers.post(API_URL.CREATE_PRICE(), price)
}

export const getProductById = ({ id, priceListId, limit, offset }) => {
  return API.offers.get(
    API_URL.PRODUCT_BY_ID({ id, priceListId, limit, offset })
  )
}

export const getItemById = ({ id, priceListId, limit, offset }) => {
  return API.offers.get(API_URL.ITEM_BY_ID({ id, priceListId, limit, offset }))
}

export const getAvailableShippingTypeOptions = () => {
  const isV2ShippingAPI =
    isFeatureActive({
      flagName: FEATURE_FLAGS.OMS_V1_SHIPPING_TYPE_API,
    }) === false

  if (isV2ShippingAPI) {
    return API.omsV2.post(
      API_URL.GET_AVAILABLE_SHIPPING_TYPES(isV2ShippingAPI),
      { filters: {}, limit: 0 }
    )
  } else {
    return API.oms.get(API_URL.GET_AVAILABLE_SHIPPING_TYPES(isV2ShippingAPI))
  }
}
export const getItemIdsBySku = (sku) => {
  const isPimV1 = isFeatureActive({ flagName: FEATURE_FLAGS.PIM_V1 })
  if (isPimV1) {
    return API.pim.post(API_URL.ITEM_IDS_BY_SKU(), sku)
  } else {
    return API.offersConnector.post(API_URL.ITEM_IDS_BY_SKU(), sku)
  }
}
export const getAvailableEligiblePriceList = ({
  sortBy = 'isDefault',
  sortOrder = 'desc',
  offset = 0,
  notExpired = false,
}) => {
  return API.offers.get(
    API_URL.GET_AVAILABLE_PRICE_LIST({ sortBy, sortOrder, offset, notExpired })
  )
}
export const updatePriceList = ({ priceListId }, data) => {
  return API.offers.put(API_URL.UPDATE_PRICE_LIST(priceListId), data)
}
export const deletePriceList = (priceListId) => {
  return API.offers.delete(API_URL.UPDATE_PRICE_LIST(priceListId))
}
export const createEligiblePriceList = (data) => {
  return API.offers.post(API_URL.CREATE_PRICE_LIST(), data)
}
export const updateEligiblePriceList = ({ id }, data) => {
  return API.offers.put(API_URL.UPDATE_SKU_LIST(id), data)
}

export const downloadErrorObject = (body) => {
  return API.offers.post(API_URL.IMPORT_ERROR_OBJECT, body)
}
export const getDefaultChannels = () => {
  return API.locale.get(API_URL.GET_DEFAULT_CHANNEL)
}

export const getLocalesList = () => {
  return API.locale.get(API_URL.GET_LOCALES_LIST)
}
