import { parse } from 'papaparse'
import { buildCategoryCollectionProductSearchQuery, buildSkuProductSearchQuery, buildVariantsProductSearchQuery } from '../../../helper/sku'

export const csvReader = ({ files }, getParseData) => {
  var parseData,
    file = files[0]
  const reader = new FileReader()
  reader.onload = (e) => {
    parseData = parse(e.target.result, {
      header: true,
      skipEmptyLines: 'greedy',
      transform: (value) => value.trim(),
    })
    getParseData(parseData)
  }
  reader.readAsText(file)
}

export const changeChildrenState = (element, state) => {
  if (element === null || element?.children?.length === 0) return element
  return element?.children?.map((child) => {
    child.selected = state
    changeChildrenState(child, state)
    return child
  })
}

const resetTree = (element) => {
  if (element === null) return element
  if (element?.children === null || element?.children?.length === 0) {
    return element
  }
  element.selected = false
  changeChildrenState(element, false)
  return element
}

export const resetAll = (elements) => {
  const results = elements?.map((element) => {
    return resetTree(element)
  })
  return results
}

const checkTreeRecursive = (element, id, parent, childParent) => {
  if (element.id === id) {
    if((childParent && parent && childParent?.id === parent?.id) || !parent || !childParent) {
      
      element.selected = !element.selected
      changeChildrenState(element, element.selected)
    }
    return element
  } else if (element.children != null) {
    for (let i = 0; i < element.children.length; i++) {
      element.children[i] = checkTreeRecursive(element.children[i], id, parent, element)
    }
    return element
  }
  return element
}

export const searchTree = (elements, id, parent) => {
  const results = elements?.map((element) => {
    return checkTreeRecursive(element, id, parent)
  })
  return results
}

export const searchTreeRecursive = (element, id) => {
  if (element.id === id) {
    element.selected = !element.selected
    changeChildrenState(element, element.selected)
    return element
  } else if (element.children != null) {
    for (let i = 0; i < element.children.length; i++) {
      element.children[i] = checkTreeRecursive(element.children[i], id)
    }
    return element
  }
  return element
}

const getAllSelectedCategories = (element, selectedCategories = []) => {
  if (element.selected) {
    selectedCategories.push({ id: element._id, title: element.name })
  }
  if (element && element?.children?.length) {
    getSelectedList(element.children, selectedCategories)
  }
}

export const getSelectedList = (elements, selectedCategories = []) => {
  elements.forEach((element) => {
    getAllSelectedCategories(element, selectedCategories)
  })
  return selectedCategories
}

export const getItemTree = (elements, id) => {
  const results = elements?.map((element) => {
    return searchTreeRecursive(element, id)
  })
  return results
}

export const isAnyChecked = (elements) => {
  const results = elements?.map((element) => {
    const out = isAnyCheckedRecursive(element)
    return out
  })
  return results.some((result) => result === true)
}

const isAnyCheckedRecursive = (element) => {
  if (element === null) return false
  if (element.children === null || element.children?.length === 0)
    return element?.selected || false
  if (element?.selected === true) return true
  return element?.children
    ?.map((child) => isAnyCheckedRecursive(child))
    .some((x) => x === true)
}

export const findParentById = (tree, id, parents = []) => {
  for (const node of tree) {
    if (node.id === id) {
      return [...parents, node]
    }
    if (node?.children) {
      const result = findParentById(node.children, id, [...parents, node])
      if (result) {
        return result
      }
    }
  }
  return null
}

export const prepareProductSearchRequest = ({ request, type }) => {
  const { keyword, offset, limit } = request;
  if(type === 'sku' && keyword) {
    return buildSkuProductSearchQuery({  keyword, offset, limit })
  } else if(type === 'variants' || (!keyword && type === 'sku')) {
    return buildVariantsProductSearchQuery({ keyword, offset, limit })
  } else {
    return buildCategoryCollectionProductSearchQuery({ requestParams: request, type, offset, limit })
  }
}
