import { theme } from 'ds4-beta'
import styled from 'styled-components'

export let StylePriceKindContainer = styled.div`
  padding-top: ${theme.space[4]};
`
export let StyledRadioContainer = styled.div`
  display: flex;
  gap: 40px;
  padding-top: 8.5px;

  .radio-label {
    padding: 0;
  }

  .label {
  }
`

export const StyledCouponHeading = styled.div`
  ${theme.typography.body1.medium};
`

export const StyledCouponSubText = styled.div`
  ${theme.typography.body1.regular};
  color: ${theme.color.grey[600]}
`

export const StyledCouponAppliedToHeading = styled.div`
  padding-bottom: ${theme.space[2]};
  ${theme.typography.fieldLabel};
  color: ${theme.color.grey[900]};
`

export let StyledHeading = styled.h6`
  ${theme.typography.body1.regular}
  color: ${theme.color.grey[1000]};
  margin: 0;
  padding: 0;
`
