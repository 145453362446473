export const CONDITION_OPTIONS = [
  {
    dataTestid: 'and-state',
    text: 'AND',
  },
  {
    dataTestid: 'or-state',
    text: 'OR',
  },
]

export const PRODUCT_TARGET_OPTIONS = (isV3APIEnabled = false) => [
  {
    id: 'all',
    label: `All ${isV3APIEnabled ? 'Product' : 'SKU'}s`,
    value: 'All',
    state: '',
    countField: '',
  },
  {
    id: 'SKU',
    label: `${isV3APIEnabled ? 'Product' : 'SKU'}s`,
    value: 'SKUs',
    state: 'Products',
    countField: 'FinalSelectedSkusCount',
    getFormattedData: (products) => {
      const skus = products.find((product) => product.key === 'SKUs')
      if (skus) {
        return skus?.values?.map((value, idx) => {
          return {
            label: value.sku || value?.productSku,
            ...value,
            _id: value?._id || value?.productId,
            id: idx,
          }
        })
      }
    },
  },
  {
    id: 'category',
    label: 'Category',
    value: 'Categories',
    state: 'Products',
    countField: 'FinalCategoriesSkusCount',
    getFormattedData: (products) => {
      const categories = products.find(
        (product) => product.key === 'Categories'
      )
      if (categories) {
        return categories?.values?.map((value) => {
          return {
            label: value.title,
            ...value,
          }
        })
      }
    },
  },
  {
    id: 'collection',
    label: 'Collection',
    value: 'Collections',
    state: 'Products',
    countField: 'FinalCollectionsSkusCount',
    getFormattedData: (products) => {
      const collections = products.find(
        (product) => product.key === 'Collections'
      )
      if (collections) {
        return collections?.values?.map((value) => {
          return {
            label: value.title,
            ...value,
          }
        })
      }
    },
  },
  {
    id: 'attribute',
    label: 'Attribute',
    value: 'Attributes',
    state: 'SelectedAttributes',
    countField: 'FinalAttributesSkusCount',
    getFormattedData: (selections) =>
      selections.map((selection) => {
        return {
          ...selection,
          label: selection.title,
          _id: selection.id,
          id: selection.title,
        }
      }),
  },
]

export const MAX_TAG_COUNT = {
  SKUs: 50,
  Categories: 3,
  Collections: 3,
  Attributes: 3,
}
